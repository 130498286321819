import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from "./layout/admin-layout/admin-layout.component";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { ConsumptionMeterListComponent } from "./views/consumption-meter-list/consumption-meter-list.component";
import { OperatingCostListComponent } from "./views/operating-cost-list/operating-cost-list.component";
import { ReportComponent } from "./views/report/report.component";
import { SupplierCostListComponent } from "./views/supplier-cost-list/supplier-cost-list.component";
import { PeriodListComponent } from "./views/period-list/period-list.component";
import { AdvancedPaymentsListComponent } from './views/advanced-payments-list/advanced-payments-list.component';
import { AuthGuard } from "./auth.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: '',
      class: 'fa fa-home'
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      }, {
        path: 'consumption-meters',
        component: ConsumptionMeterListComponent
      }, {
        path: 'operating-costs',
        component: OperatingCostListComponent
      }, {
        path: 'supplier-costs',
        component: SupplierCostListComponent
      }, {
        path: 'advanced-payments',
        component: AdvancedPaymentsListComponent
      }, {
        path: 'periods',
        component: PeriodListComponent
      }, {
        path: 'report',
        component: ReportComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
