import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {MeasuredValuesByMeterAndDate} from "../models/measured-values-by-meter-and-date";
import MeasuredValueModel = HausverwalterRestApi.MeasuredValueModel;

@Injectable({
  providedIn: 'root'
})
export class MeasuredValueService {

  constructor(private httpClient: HttpClient) {
  }

  upsert(meterId: number, date: string, model: HausverwalterRestApi.MeasuredValueUpsertModel): Observable<any> {
    return this.httpClient.put(`/api/consumption-meters/${meterId}/${date}`, model);
  }

  findByDateRange(startDate: Date, endDate: Date) {
    return this.httpClient.get<MeasuredValueModel[]>(`/api/measured-values?minDate=${startDate}&maxDate=${endDate}`);
  }
}
