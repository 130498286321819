<div class="modal-header">
  <h5 class="modal-title">Kosten</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<form #form="ngForm" (submit)="save()">
  <div class="modal-body" *ngIf="model">
    <div class="form-group mb-3">
      <label class="form-label" for="year">Jahr</label>
      <input id="year" type="number" name="year" class="form-control"
             [(ngModel)]="model.date" readonly>
    </div>


    <div class="form-group mb-3">
      <label class="form-label" for="date">Datum</label>
      <input id="date" type="date" name="date" class="form-control"
             [(ngModel)]="model.date">
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="text">Beschreibung</label>
      <input id="text" type="text" name="text" class="form-control"
             [(ngModel)]="model.subject" required>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="amount">Betrag</label>
      <input id="amount" type="text" name="amount" class="form-control" currencyMask
             [(ngModel)]="model.amount" required>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="wageCostAmount">Davon Lohnkosten</label>
      <input id="wageCostAmount" type="text" name="wageCostAmount" class="form-control" currencyMask
             [(ngModel)]="model.wageCostAmount" required>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="distributionKey">Umlage</label>
      <select id="distributionKey" name="distributionKey" class="form-select" [(ngModel)]="model.distributionKey" required>
        <option [value]="'FLOOR_AREA'">Fläche</option>
        <option [value]="'PARTY'">Einheit</option>
        <option [value]="'SHARE'">Eigentumsanteil</option>
        <option [value]="'MAINTENANCE'">Instandhaltung</option>
      </select>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="note">Notizen</label>
      <input id="note" type="text" name="note" class="form-control"
             [(ngModel)]="model.note" >
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" tabindex class="btn btn-link link-secondary mr-auto" data-dismiss="modal" (click)="activeModal.dismiss()">
      Abbrechen
    </button>
    <button type="button" class="btn btn-outline-primary" [disabled]="!form.valid" (click)="save()">Speichern</button>
  </div>
</form>
