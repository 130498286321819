import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PeriodService } from "../../core/services/period.service";
import PeriodModel = HausverwalterRestApi.PeriodModel;

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  isCollapsed = true;
  navItems: NavItem[] = [];
  initials = '';
  periods?: PeriodModel[];
  selectedPeriod: any;

  constructor(
    private router: Router,
    private location: Location,
    private periodService: PeriodService,
  ) {
    router.events.subscribe(val => {
      if (location.path() != "") {
        const currentLocation = location.path() || '';
        this.navItems.forEach(item => {
          item.active = currentLocation.startsWith(item.link);
        });
      }
    });

    this.periodService.findAll().subscribe(result => this.periods = result);

    this.periodService.current.subscribe(next => this.selectedPeriod = next)
  }

  ngOnInit() {
    this.updateNavigation();
  }

  click() {
    this.isCollapsed = true;
  }

  periodChanged(selected: PeriodModel) {
    this.periodService.current.next(selected);
  }

  isSamePeriod(p1: PeriodModel, p2: PeriodModel) {
    return p1?.id === p2?.id;
  }

  private updateNavigation() {
    const result = [];

    result.push({
      label: 'Dashboard',
      link: '/dashboard',
      icon: 'dashboard',
      active: false,
      visible: true
    });

    result.push({
      label: 'Zählerstände',
      icon: 'home',
      link: '/consumption-meters',
      active: false,
      visible: true
    });

    result.push({
      label: 'Kosten',
      icon: 'home',
      link: '/operating-costs',
      active: false,
      visible: true
    });

    result.push({
      label: 'Versorger',
      icon: 'home',
      link: '/supplier-costs',
      active: false,
      visible: true
    });

    result.push({
      label: 'Vorauszahlungen',
      icon: 'home',
      link: '/advanced-payments',
      active: false,
      visible: true
    });

    result.push({
      label: 'Perioden',
      icon: 'home',
      link: '/periods',
      active: false,
      visible: true
    });

    result.push({
      label: 'Report',
      icon: 'home',
      link: '/report',
      active: false,
      visible: true
    })


    this.navItems = result;
  }
}

interface NavItem {
  label: string;
  link: string;
  icon?: string;
  active: boolean,
  visible: boolean
}
