<div class="page-wrapper">
  <div class="container-xl">
    <div class="page-header">
      <div class="row align-items-baseline">
        <div class="col">
          <h2 class="page-title">Abrechnungsperioden</h2>
        </div>
        <div class="col-auto ms-auto">
          <div class="btn-list">
            <button class="btn btn-primary" (click)="prepareNew()">Neu</button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="table-responsive" *ngIf="list">
              <table class="table card-table">
                <thead>
                <tr>
                  <th>Start</th>
                  <th>Ende</th>
                  <th>Abgeschlossen</th>
                  <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of list">
                  <td>{{row.startDate }}</td>
                  <td>{{row.endDate}}</td>
                  <td>{{row.done }}</td>
                  <td>
                    <button class="btn btn-sm btn-secondary" *ngIf="!row.done" (click)="editDetailsModal(row)">Details</button>
                    <button class="btn btn-sm btn-secondary" *ngIf="!row.done" (click)="close(row)">Abschließen</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
