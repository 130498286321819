<div class="modal-header">
  <h5 class="modal-title">Kosten</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()">
  </button>
</div>
<form #form="ngForm" (submit)="save()">
  <div class="modal-body" *ngIf="model">
    <div class="form-group mb-3">
      <label class="form-label" for="year">Jahr</label>
      <input id="year" type="number" name="year" class="form-control"
             [(ngModel)]="year" readonly>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="text">Beschreibung</label>
      <input id="text" type="text" name="text" class="form-control"
             [(ngModel)]="model.description" required>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="amount">Betrag</label>
      <input id="amount" type="number" name="amount" class="form-control"
             [(ngModel)]="model.amount" required>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="quantity">Menge <ng-container *ngIf="unitText"> ({{ unitText }})</ng-container></label>
      <input id="quantity" type="number" name="quantity" class="form-control"
             [(ngModel)]="model.quantity" required>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="type">Typ</label>
      <select id="type" name="type" class="form-select" [(ngModel)]="model.type" required (select)="typeChanged()">
        <option [value]="'GAS'">Gas</option>
        <option [value]="'GAS_SOFORTHILFE'">Gas Soforthilfe</option>
        <option [value]="'ELECTRICITY'">Strom</option>
        <option [value]="'WATER'">Frischwasser</option>
        <option [value]="'WASTEWATER'">Abwasser</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" tabindex class="btn btn-link link-secondary mr-auto" data-dismiss="modal" (click)="activeModal.dismiss()">
      Abbrechen
    </button>
    <button type="button" class="btn btn-outline-primary" [disabled]="!form.valid" (click)="save()">Speichern</button>
  </div>
</form>
