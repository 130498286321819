<div class="modal-header">
  <h4 class="modal-title">PDF Vorschau</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">
  <ngx-extended-pdf-viewer [src]="data" height="80vh"></ngx-extended-pdf-viewer>

</div>
