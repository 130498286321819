import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import OperatingCostModel = HausverwalterRestApi.OperatingCostModel;
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OperationalCostService {

  constructor(private httpClient: HttpClient) {}

  findByYear(year: number) {
    return this.httpClient.get<OperatingCostModel[]>(`/api/operating-costs/${year}`);
  }

  createNew(model: OperatingCostModel) {
    return this.httpClient.post(`/api/operating-costs`, model);
  }

  update(model: HausverwalterRestApi.OperatingCostModel) {
    return this.httpClient.put(`/api/operating-costs/${model.id}`, model);
  }

  delete(id: number) : Observable<any> {
    return this.httpClient.delete(`/api/operating-costs/${id}`);
  }
}
