<div class="modal-header">
  <h4 class="modal-title">Perioden Details
    <ng-container *ngIf="period">
      <h4 class="">{{period.startDate}} - {{period.endDate}}</h4>
    </ng-container>
  </h4>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()">
  </button>
</div>
<form #form="ngForm" (submit)="save()">
  <div class="modal-body" *ngIf="model && period">
    <h3>Hausgeldkonto</h3>
    <div class="form-group mb-3">
      <label class="form-label" for="hausgeldEnde">Kontostand Begin</label>
      <input id="hausgeldStart" type="text" name="hausgeldStart" class="form-control"
             [(ngModel)]="period.hausgeldStart" readonly currencyMask>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="hausgeldEnde">Kontostand Ende</label>
      <input id="hausgeldEnde" type="text" name="hausgeldEnde" class="form-control"
             [(ngModel)]="model.hausgeldEnde" currencyMask>
    </div>

    <h3>Instandhaltungsrücklage</h3>
    <div class="form-group mb-3">
      <label class="form-label" for="instandhaltungsruecklageStart">Kontostand Start</label>
      <input id="instandhaltungsruecklageStart" type="text" name="instandhaltungsruecklageStart" class="form-control"
             [(ngModel)]="period.instandhaltungsruecklageStart" readonly currencyMask>
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="instandhaltungsruecklageEnde">Kontostand Ende</label>
      <input id="instandhaltungsruecklageEnde" type="text" name="instandhaltungsruecklageEnde" class="form-control"
             [(ngModel)]="model.instandhaltungsruecklageEnde" placeholder currencyMask>
    </div>


    <h3>Verbindlichkeiten</h3>
    <div class="form-group mb-3">
      <label class="form-label" for="verbindlichkeitenVorjahr">aus dem Vorjahr</label>
      <input id="verbindlichkeitenVorjahr" type="text" name="verbindlichkeitenVorjahr" class="form-control"
             [(ngModel)]="period.verbindlichkeitenVorjahr" readonly currencyMask>
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="verbindlichkeitenFolgejahr">ins Folgejahr</label>
      <input id="verbindlichkeitenFolgejahr" type="text" name="verbindlichkeitenFolgejahr" class="form-control"
             [(ngModel)]="model.verbindlichkeitenFolgejahr" placeholder currencyMask>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" tabindex class="btn btn-link link-secondary mr-auto" data-dismiss="modal"
            (click)="activeModal.dismiss()">
      Abbrechen
    </button>
    <button type="button" class="btn btn-outline-primary" [disabled]="!form.valid" (click)="save()">Speichern</button>
  </div>
</form>
