import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, Subject } from "rxjs";
import PeriodModel = HausverwalterRestApi.PeriodModel;

@Injectable({
  providedIn: 'root'
})
export class PeriodService {

  current: Subject<PeriodModel> = new ReplaySubject(1);

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  fetchLatest(): Observable<PeriodModel> {
    return this.httpClient.get<PeriodModel>('/api/periods/latest');
  }

  findAll(): Observable<PeriodModel[]> {
    return this.httpClient.get<PeriodModel[]>('/api/periods');
  }

  close(id: number): Observable<any> {
    return this.httpClient.post(`/api/periods/${id}/close`, undefined);
  }

  prepareNew(): Observable<any> {
    return this.httpClient.post(`/api/periods`, undefined);
  }

  updateDetails(id: number, model: HausverwalterRestApi.PeriodUpdateModel) {
    return this.httpClient.put(`/api/periods/${id}`, model);
  }
}
