import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import SupplierCostModel = HausverwalterRestApi.SupplierCostModel;

@Injectable({
  providedIn: 'root'
})
export class SupplierCostService {

  constructor(private httpClient: HttpClient) { }

  findByPeriod(periodId: number): Observable<SupplierCostModel[]> {
    return this.httpClient.get<SupplierCostModel[]>(`/api/periods/${periodId}/supplier-costs`);
  }

  update(model: SupplierCostModel) {
    return this.httpClient.put(`/api/supplier-costs/${model.id}`, model);
  }

  createNew(model: SupplierCostModel) {
    return this.httpClient.post(`/api/periods/${model.periodId}/supplier-costs`, model);
  }
}
