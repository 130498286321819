import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedPaymentService } from '../../core/services/advanced-payment.service';
import { mergeMap, switchMap, take } from 'rxjs/operators';
import { interval, Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import AdvancePaymentUpsertModel = HausverwalterRestApi.AdvancePaymentUpsertModel;
import OwnerModel = HausverwalterRestApi.OwnerModel;
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-advanced-payment-upsert-dialog',
  templateUrl: './advanced-payment-upsert-dialog.component.html',
  styleUrls: ['./advanced-payment-upsert-dialog.component.scss']
})
export class AdvancedPaymentUpsertDialogComponent implements OnInit {

  paymentDate?: Date;
  paymentAmount?: number;

  count = 1;

  owner?: OwnerModel;
  period?: HausverwalterRestApi.PeriodModel;

  uuid?: string;

  constructor(
    public activeModal: NgbActiveModal,
    private advancedPaymentService: AdvancedPaymentService
  ) {
  }

  ngOnInit(): void {
  }

  save() {
    if (!this.period || !this.owner) {
      alert("invalid state");
      return;
    }

    // @ts-ignore
    if (!this.paymentDate || this.paymentDate > this.period?.endDate || this.paymentDate < this.period?.startDate) {
      alert("Datum außerhalb der Periode");
      return;
    }

    // @ts-ignore
    if (!this.paymentAmount || this.paymentAmount === 0) {
      alert("Betrag darf nicht leer oder 0 sein");
      return;
    }

    if (!this.count || this.count < 1) {
      alert('Anzahl muss mindestens 1 sein');
      return;
    }

    let subject: Observable<any>;

    if (this.uuid) {
      subject = this.update();
    } else {
      subject = this.insertNew();
    }

    subject.subscribe(result => this.activeModal.close());
  }

  private insertNew() {
    return interval(0)
      .pipe(
        take(this.count),
        mergeMap(number => this.insertMonth(number)),
      );
  }

  private insertMonth(counter: number): Observable<any> {
    let paymentDate = moment.utc(this.paymentDate).add('month', counter)

    const model: AdvancePaymentUpsertModel = {
      // @ts-ignore
      amount: this.paymentAmount,
      date: paymentDate.toDate()
    }

    const uuid = uuidv4();

    // @ts-ignore
    return this.advancedPaymentService.upsert(this.period.id, this.owner.id, uuid, model);
  }

  private update() {
    const model = {
      amount: this.paymentAmount,
      date: this.paymentDate
    };

    // @ts-ignore
    return this.advancedPaymentService.upsert(this.period?.id, this.owner?.id, this.uuid, model);
  }
}
