<div class="page-wrapper">
  <div class="container-xl">
    <div class="page-header">
      <div class="row align-items-baseline">
        <div class="col">
          <h2 class="page-title">Bericht</h2>
        </div>
        <div class="col-auto ms-auto">
          <div class="btn-list">
            <button class="btn btn-primary" (click)="generateNew(true)">Entwurf</button>
            <button class="btn btn-primary" (click)="generateNew(false)">Erstellen</button>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="row">
        <div class="col">
          <div class="card" *ngFor="let report of reports">
            <div class="card-body">
              <div>Erstellt am: {{report.generatedAt | date:'medium'}}</div>
              <div>Status: {{report.draft ? 'Draft' : 'Final'}}</div>
              <div>
                <button class="btn btn-primary" (click)="delete(report)" *ngIf="report.draft">Löschen</button>
                <button class="btn btn-primary" (click)="showmodal(report.id)">Anzeigen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
