import { Component, OnInit } from '@angular/core';
import { PeriodService } from "../../core/services/period.service";
import PeriodModel = HausverwalterRestApi.PeriodModel;
import {
  AdvancedPaymentUpsertDialogComponent
} from "../advanced-payment-upsert-dialog/advanced-payment-upsert-dialog.component";
import {from} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  PeriodDetailsUpsertDialogComponent
} from "../period-details-upsert-dialog/period-details-upsert-dialog.component";

@Component({
  selector: 'app-period-list',
  templateUrl: './period-list.component.html',
  styleUrls: ['./period-list.component.scss']
})
export class PeriodListComponent implements OnInit {

  public list?: PeriodModel[];

  constructor(
    private periodService: PeriodService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.periodService.findAll().subscribe(
      result => this.list = result
    )
  }

  close(row: HausverwalterRestApi.PeriodModel) {
    this.periodService.close(row.id).subscribe(
      success => {
        this.refresh();
      }
    );
  }

  prepareNew() {
    this.periodService.prepareNew().subscribe(
      success => {
        this.refresh();
      }
    )
  }

  editDetailsModal(row: HausverwalterRestApi.PeriodModel) {
    const modalRef = this.modalService.open(PeriodDetailsUpsertDialogComponent, {backdrop: "static"});
    const instance: PeriodDetailsUpsertDialogComponent = modalRef.componentInstance;

    instance.load(row);

    from(modalRef.result).subscribe(
      success => {
        this.refresh();
      }
    )
  }
}
