import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OperationalCostService } from "../../core/services/operational-cost.service";
import { Observable } from "rxjs";
import PeriodModel = HausverwalterRestApi.PeriodModel;
import OperatingCostModel = HausverwalterRestApi.OperatingCostModel;

@Component({
  selector: 'app-operating-cost-upsert-dialog',
  templateUrl: './operating-cost-upsert-dialog.component.html',
  styleUrls: ['./operating-cost-upsert-dialog.component.scss']
})
export class OperatingCostUpsertDialogComponent implements OnInit {
  model: any = {};
  period?: PeriodModel;

  constructor(
    public activeModal: NgbActiveModal,
    private operationalCostService: OperationalCostService,
  ) {
  }

  ngOnInit(): void {
  }

  save() {
    if (!this.period) {
      alert('Abrechnungsperiode nicht aktiv');
      return;
    }

    if (this.model.date > this.period.endDate || this.model.date < this.period.startDate) {
      alert('Datum außerhalb der Abrechnungsperiode');
      return;
    }

    let result: Observable<any>;

    if (this.model.id) {
      result = this.operationalCostService.update(this.model);
    } else {
      result = this.operationalCostService.createNew(this.model);
    }

    result.subscribe(
      success => this.activeModal.close()
    );
  }
}
