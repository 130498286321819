import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import ReportAbstractModel = HausverwalterRestApi.ReportAbstractModel;

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private httpClient: HttpClient) { }

  fetchByPeriodId(periodId: number): Observable<ReportAbstractModel[]> {
    return this.httpClient.get<ReportAbstractModel[]>(`/api/reports?periodId=${periodId}`);
  }

  fetchContentByReportId(id: number): Observable<Blob> {
    return this.httpClient.get(`/api/reports/${id}/content`, { responseType: 'blob'});
  }

  generateNewForYear(periodId: number, isDraft: boolean): Observable<Blob> {
    return this.httpClient.post(`/api/reports/${periodId}?isDraft=${isDraft}`, undefined, { responseType: 'blob'});
  }

  deleteById(id: number): Observable<any> {
    return this.httpClient.delete(`/api/reports/${id}`);
  }
}
