import {Component} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {PeriodService} from "../../core/services/period.service";
import PeriodUpdateModel = HausverwalterRestApi.PeriodUpdateModel;
import PeriodModel = HausverwalterRestApi.PeriodModel;

@Component({
  selector: 'app-period-details-upsert-dialog',
  templateUrl: './period-details-upsert-dialog.component.html',
  styleUrls: ['./period-details-upsert-dialog.component.scss']
})
export class PeriodDetailsUpsertDialogComponent {

  period?: PeriodModel;
  model?: PeriodUpdateModel;

  constructor(
    public activeModal: NgbActiveModal,
    private periodService: PeriodService
  ) {
  }

  save() {
    if (!this.period) {
      return;
    }

    if (!this.model) {
      return;
    }

    this.periodService.updateDetails(this.period.id, this.model).subscribe(
      result => this.activeModal.close(),
      error => alert(error)
    );
  }

  load(row: HausverwalterRestApi.PeriodModel) {
    this.period = row;
    this.model = {
      hausgeldEnde: row.hausgeldEnde,
      verbindlichkeitenFolgejahr: row.verbindlichkeitenFolgejahr,
      instandhaltungsruecklageEnde: row.instandhaltungsruecklageEnde
    };
  }
}
