<div class="page">
  <header class="navbar navbar-expand-md navbar-dark d-print-none" data-bs-theme="dark">
    <div class="container-xl">
      <button class="navbar-toggler collapsed" type="button" (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        <a href="." class="navbar-brand d-none-navbar-horizontal pr-0 pr-md-3">
          Hausverwalter
        </a>
      </h1>
      <div class="collapse navbar-collapse" id="navbar-menu" [ngbCollapse]="isCollapsed">
        <div class="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
            <ul class="navbar-nav" *ngIf="navItems">
              <li class="nav-item" [ngClass]="{'active': item.active}"
                  *ngFor="let item of navItems">
                <a class="nav-link" [routerLink]="item.link" (click)="click()">
                  <span class="nav-link-title">{{ item.label }}</span>
                </a>
              </li>
            </ul>
        </div>
      </div>
      <div class="ml-auto" *ngIf="periods">
        <select [ngModel]="selectedPeriod" (ngModelChange)="periodChanged($event)" [compareWith]="isSamePeriod">
          <option *ngFor="let p of periods" [ngValue]="p" >{{p.startDate | date }} - {{p.endDate | date}}</option>
        </select>
      </div>
    </div>
  </header>
  <div class="content" *ngIf="selectedPeriod.id">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
