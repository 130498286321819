<div class="modal-header">
  <h4 class="modal-title">Zählerstand
  <ng-container *ngIf="meter">
    <h4 class="">{{meter.number}}</h4>
  </ng-container>
  </h4>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()">
  </button>
</div>
<form #form="ngForm" (submit)="save()">
  <div class="modal-body" *ngIf="model">
    <div class="form-group mb-3">
      <label class="form-label" for="date">Datum</label>
      <input id="date" type="date" name="date" class="form-control"
             [(ngModel)]="date" required>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="value">Zählerstand</label>
      <input id="value" type="number" name="value" class="form-control"
             [(ngModel)]="model.value" required ngbautofocus>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="type">Typ</label>
      <select id="type" type="text" name="type" class="form-select"
              [(ngModel)]="model.measureType" required>
        <option [value]="'READ'">Abgelesen</option>
        <option [value]="'CALC'">Geschätzt</option>
      </select>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" tabindex class="btn btn-link link-secondary mr-auto" data-dismiss="modal" (click)="activeModal.dismiss()">
      Abbrechen
    </button>
    <button type="button" class="btn btn-outline-primary" [disabled]="!form.valid" (click)="save()">Speichern</button>
  </div>
</form>
