<div class="page-wrapper">
  <div class="container-xl">
    <div class="page-header">
      <div class="row align-items-baseline">
        <div class="col">
          <h2 class="page-title">Kosten</h2>
        </div>
        <div class="col-auto ms-auto">
          <div class="btn-list">
            <button class="btn btn-primary" (click)="showUpsertModal()">Neu</button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="table-responsive" *ngIf="costs">
              <table class="table card-table">
                <thead>
                <tr>
                  <th>Beleg</th>
                  <th>Datum</th>
                  <th>Menge</th>
                  <th>Betrag</th>
                  <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of costs">
                  <td>{{row.description }}</td>
                  <td>{{row.type}}</td>
                  <td>{{row.quantity }}</td>
                  <td>{{row.amount | currency}}</td>
                  <td>
                    <button class="btn btn-sm btn-secondary" (click)="showEditModal(row)">Edit</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
