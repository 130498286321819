import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AdvancePaymentAbstractModel = HausverwalterRestApi.AdvancePaymentAbstractModel;

@Injectable({
  providedIn: 'root'
})
export class AdvancedPaymentService {

  constructor(private httpClient: HttpClient) {
  }

  findByPeriodIdAndOwnerId(periodId: number, ownerId: number): Observable<AdvancePaymentAbstractModel[]> {
    return this.httpClient.get<AdvancePaymentAbstractModel[]>(`/api/advanced-payments/periods/${periodId}/owners/${ownerId}`);
  }
  upsert(periodId: number,
         ownerId: number,
         uid: string,
         model: HausverwalterRestApi.AdvancePaymentUpsertModel): Observable<any> {

    return this.httpClient.put(
      `/api/advanced-payments/periods/${periodId}/owners/${ownerId}/${uid}`
      , model);
  }

  delete(periodId: number, ownerId: number, uid: string): Observable<any> {
    return this.httpClient.delete(`/api/advanced-payments/periods/${periodId}/owners/${ownerId}/${uid}`)
  }

}
