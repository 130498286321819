import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import ConsumptionMeterModel = HausverwalterRestApi.ConsumptionMeterModel;

@Injectable({
  providedIn: 'root'
})
export class ConsumptionMeterService {

  constructor(private httpClient: HttpClient) {
  }

  findById(meterId: number) {
    return this.httpClient.get<ConsumptionMeterModel>(`/api/consumption-meters/${meterId}`);
  }

  findByDateRange(startDate: Date, endDate: Date) {
    return this.httpClient.get<ConsumptionMeterModel[]>(`/api/consumption-meters?startDate=${startDate}&endDate=${endDate}`);
  }

}
