import {Component} from '@angular/core';
import {PeriodService} from "./core/services/period.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fe-hausverwalter';

  constructor(
    periodService: PeriodService,
  ) {
    periodService.fetchLatest().subscribe(
      next => periodService.current.next(next)
    )
  }
}
