import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { LayoutModule } from "./layout/layout.module";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ConsumptionMeterListComponent } from './views/consumption-meter-list/consumption-meter-list.component';
import { MeasuredValueEditDialogComponent } from "./views/measured-value-edit-dialog/measured-value-edit-dialog.component";
import { registerLocaleData } from "@angular/common";
import { OperatingCostListComponent } from "./views/operating-cost-list/operating-cost-list.component";
import { OperatingCostUpsertDialogComponent } from "./views/operating-cost-upsert-dialog/operating-cost-upsert-dialog.component";
import { ReportComponent } from './views/report/report.component';
import { SupplierCostListComponent } from './views/supplier-cost-list/supplier-cost-list.component';
import { SupplierCostUpsertModalComponent } from './views/supplier-cost-upsert-modal/supplier-cost-upsert-modal.component';
import { PdfViewModalComponent } from "./views/pdf-view-modal/pdf-view-modal.component";
import { PeriodListComponent } from "./views/period-list/period-list.component";
import localeDe from '@angular/common/locales/de';
import { AdvancedPaymentsListComponent } from './views/advanced-payments-list/advanced-payments-list.component';
import { AdvancedPaymentUpsertDialogComponent } from './views/advanced-payment-upsert-dialog/advanced-payment-upsert-dialog.component';
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import {
  PeriodDetailsUpsertDialogComponent
} from './views/period-details-upsert-dialog/period-details-upsert-dialog.component';
import { NgxCurrencyDirective, NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from "ngx-currency";
import { KeycloakAngularModule, KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { AuthGuard } from "./auth.guard";

registerLocaleData(localeDe, 'de');

function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {
    console.log('init keycloak');
    await keycloak.init({
      config: {
        url: 'https://id.nils-eckert.de/auth/',
        realm: 'nils-eckert-prod',
        clientId: 'hausverwalter'
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    });
  }
}

@NgModule({ declarations: [
        AppComponent,
        ConsumptionMeterListComponent,
        MeasuredValueEditDialogComponent,
        OperatingCostListComponent,
        OperatingCostUpsertDialogComponent,
        ReportComponent,
        SupplierCostListComponent,
        SupplierCostUpsertModalComponent,
        PdfViewModalComponent,
        PeriodListComponent,
        AdvancedPaymentsListComponent,
        AdvancedPaymentUpsertDialogComponent,
        PeriodDetailsUpsertDialogComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        LayoutModule,
        AppRoutingModule,
        KeycloakAngularModule,
        NgbModule,
        NgxCurrencyDirective,
        NgxExtendedPdfViewerModule], providers: [
        AuthGuard,
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
        provideEnvironmentNgxCurrency({
            decimal: ",",
            precision: 2,
            prefix: "",
            suffix: "€",
            thousands: ".",
            nullable: true,
            min: undefined,
            max: undefined,
            inputMode: NgxCurrencyInputMode.Financial,
        }),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
