import {Component, OnInit} from '@angular/core';
import {ConsumptionMeterService} from "../../core/services/consumption-meter.service";
import {PeriodService} from "../../core/services/period.service";
import {take} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MeasuredValueEditDialogComponent} from "../measured-value-edit-dialog/measured-value-edit-dialog.component";
import { from } from 'rxjs';
import {MeasuredValueService} from "../../core/services/measured-value.service";
import {max as _max, min as _min, uniqBy as _uniqBy} from 'lodash';
import ConsumptionMeterModel = HausverwalterRestApi.ConsumptionMeterModel;
import PeriodModel = HausverwalterRestApi.PeriodModel;
import MeasuredValueModel = HausverwalterRestApi.MeasuredValueModel;
import OwnerModel = HausverwalterRestApi.OwnerModel;

@Component({
  selector: 'app-consumption-meter-list',
  templateUrl: './consumption-meter-list.component.html',
  styleUrls: ['./consumption-meter-list.component.scss']
})
export class ConsumptionMeterListComponent implements OnInit {

  ownerWithMetersAndValues: OwnerWithMetersAndValues[] = [];
  dates: string[] = [];
  period?: PeriodModel;

  constructor(
    private consumptionMeterService: ConsumptionMeterService,
    private periodService: PeriodService,
    private measuredValueService: MeasuredValueService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.periodService.current.subscribe(
      period => {
        this.period = period;

        this.consumptionMeterService.findByDateRange(period.startDate, period.endDate).subscribe(
          meters => {
            this.ownerWithMetersAndValues = [];

            const ownersList: OwnerModel[] = _uniqBy(meters.map(m => m.owner), m => m.id);

            ownersList.forEach(
              owner => {
                const ownerMeters = meters.filter(m => m.owner.id === owner.id).map(cm => {
                    // @ts-ignore
                    const startValue = {
                      meterId: cm.id,
                      value: undefined,
                      date: this.determineMeterStartDate(cm),
                      measureType: "READ"
                    } as MeasuredValueModel;

                    // @ts-ignore
                    const endValue = {
                      meterId: cm.id,
                      value: undefined,
                      date: this.determineMeterEndDate(cm),
                      measureType: "READ"
                    } as MeasuredValueModel;

                    return {
                      meter: cm,
                      values: [],
                      startValue: startValue,
                      endValue: endValue,
                    } as MeterWithValues
                  }
                )

                this.ownerWithMetersAndValues.push({
                    owner: owner,
                    metersWithValues: ownerMeters
                  }
                )
              }
            )

            this.refreshValues();
          }
        )
      }
    )

  }

  editValue(value: HausverwalterRestApi.MeasuredValueModel) {
    const modalRef = this.modalService.open(MeasuredValueEditDialogComponent, {backdrop: "static"});
    const instance: MeasuredValueEditDialogComponent = modalRef.componentInstance;

    instance.load(value);

    from(modalRef.result).subscribe(
      success => {
        this.refreshValues();
      }
    )
  }

  private refreshValues() {
    if (!this.period) {
      return;
    }

    this.measuredValueService.findByDateRange(this.period.startDate, this.period.endDate).subscribe(
      values => {
        this.ownerWithMetersAndValues.forEach(owm => {
          owm.metersWithValues.forEach(mwv => {
              const meterValues = values.filter(v => v.meterId === mwv.meter.id)
              this.updateValue(mwv.startValue, meterValues);
              this.updateValue(mwv.endValue, meterValues);
            }
          );
        });
      }
    )
  }

  private determineMeterStartDate(meter: HausverwalterRestApi.ConsumptionMeterModel) {
    if (!this.period) {
      throw new Error();
    }

    const minDate = <Date>_max([meter.installedDate, this.period.startDate]);
    return minDate;
  }

  private determineMeterEndDate(meter: HausverwalterRestApi.ConsumptionMeterModel): Date {
    if (!this.period) {
      throw new Error();
    }

    return <Date>_min([meter.removedDate, this.period.endDate]);
  }

  private updateValue(valueModel: HausverwalterRestApi.MeasuredValueModel, values: HausverwalterRestApi.MeasuredValueModel[]): void {
    const foundValueModel = values.find(v => v.date === valueModel.date);

    if (foundValueModel) {
      valueModel.value = foundValueModel.value;
    } else {
      // @ts-ignore
      valueModel.value = undefined;
    }
  }

}

interface OwnerWithMetersAndValues {
  owner: OwnerModel;
  metersWithValues: MeterWithValues[];
}

interface MeterWithValues {
  meter: ConsumptionMeterModel;
  values: MeasuredValueModel[];
  startValue: MeasuredValueModel;
  endValue: MeasuredValueModel;
}
