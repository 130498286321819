import { Component, OnInit } from '@angular/core';
import { PeriodService } from "../../core/services/period.service";
import { OperationalCostService } from "../../core/services/operational-cost.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { from } from 'rxjs';
import OperatingCostsModel = HausverwalterRestApi.OperatingCostModel;
import { OperatingCostUpsertDialogComponent } from "../operating-cost-upsert-dialog/operating-cost-upsert-dialog.component";
import moment from 'moment';

@Component({
  selector: 'app-operating-cost-list',
  templateUrl: './operating-cost-list.component.html',
  styleUrls: ['./operating-cost-list.component.scss']
})
export class OperatingCostListComponent implements OnInit {

  public costs?: OperatingCostsModel[];
  public period?: HausverwalterRestApi.PeriodModel;

  constructor(
    private periodService: PeriodService,
    private operationalCostService: OperationalCostService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.periodService.current.subscribe(
      period => {
        this.period = period;
        this.refreshCosts();
      }
    )
  }

  showUpsertModal() {
    if (!this.period) {
      alert("Abrechnungsperiode nicht gesetzt");
      return;
    }

    const year = moment(this.period.startDate).year();

    this.showEditModal({year: year} as OperatingCostsModel);
  }

  showEditModal(model: HausverwalterRestApi.OperatingCostModel) {
    this.showUpsertDialog({ ...model });
  }

  showCopyModal(model: HausverwalterRestApi.OperatingCostModel) {
    let clone = { ...model, ...{ id: undefined, date: undefined } };
    this.showUpsertDialog(clone);
  }

  private showUpsertDialog(model: any) {
    const modalRef = this.modalService.open(OperatingCostUpsertDialogComponent, {backdrop: "static"});
    const instance: OperatingCostUpsertDialogComponent = modalRef.componentInstance;

    instance.model = { ...model, type: 'OTHER' };
    instance.period = this.period;

    from(modalRef.result).subscribe(
      success => {
        this.refreshCosts();
      }
    )
  }

  private refreshCosts() {
    if (!this.period) {
      return;
    }

    const year = moment(this.period.startDate).year();

    this.operationalCostService.findByYear(year).subscribe(
      costs => this.costs = costs
    )
  }


  deleteWithConfirm(row: HausverwalterRestApi.OperatingCostModel) {
    if (!confirm("Wirklich löschen?")) {
      return;
    }

    // @ts-ignore
    this.operationalCostService.delete(row.id).subscribe(
      () => this.refreshCosts(),
      error => alert(JSON.stringify(error))
    );
  }
}
