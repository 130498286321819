<div class="container-xl">
  <div class="row">
    <div class="col-12">
      <div *ngFor="let ownerWithMetersAndValues of ownerWithMetersAndValues">
        <h3 class="mt-3">{{ownerWithMetersAndValues.owner.name}}</h3>
        <div class="card">
          <div class="table-responsive" *ngIf="ownerWithMetersAndValues">
            <table class="table card-table">
              <thead>
              <tr>
                <th>Name</th>
                <th>Nummer</th>
                <th>Medium</th>
                <th>Anfangswert</th>
                <th>Endwert</th>
                <th>Verbrauch</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let row of ownerWithMetersAndValues.metersWithValues">
                <td>{{row.meter.description}}</td>
                <td>{{row.meter.number}}</td>
                <td>{{row.meter.medium}}</td>
                <td>
                  <ng-container *ngIf="row.startValue">
                    {{row.startValue.value | number }}
                  </ng-container>
                  <a (click)="editValue(row.startValue)"><i class="ti ti-edit"></i></a>
                </td>
                <td>
                  <ng-container *ngIf="row.endValue">
                    {{row.endValue.value | number }}
                  </ng-container>
                  <a (click)="editValue(row.endValue)"><i class="ti ti-edit"></i></a>
                </td>
                <td>
                  <ng-container *ngIf="row.startValue.value && row.endValue.value">
                    {{ row.endValue.value - row.startValue.value | number }}
                  </ng-container>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
