import { Component, OnInit } from '@angular/core';
import { PeriodService } from "../../core/services/period.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { SupplierCostService } from "../../core/services/supplier-cost.service";
import { SupplierCostUpsertModalComponent } from "../supplier-cost-upsert-modal/supplier-cost-upsert-modal.component";
import SupplierCostModel = HausverwalterRestApi.SupplierCostModel;
import PeriodModel = HausverwalterRestApi.PeriodModel;
import { from } from 'rxjs';

@Component({
  selector: 'app-supplier-cost-list',
  templateUrl: './supplier-cost-list.component.html',
  styleUrls: ['./supplier-cost-list.component.scss']
})
export class SupplierCostListComponent implements OnInit {

  public costs?: SupplierCostModel[];
  private period?: PeriodModel;

  constructor(
    private periodService: PeriodService,
    private supplierCostService: SupplierCostService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.periodService.current.subscribe(
      period => {
        this.period = period;
        this.refreshCosts();
      }
    )
  }

  showUpsertModal() {
    if (!this.period) {
      alert("Abrechnungsperiode nicht gesetzt");
      return;
    }

    this.showEditModal({periodId: this.period.id} as SupplierCostModel);
  }

  showEditModal(model: SupplierCostModel) {
    const modalRef = this.modalService.open(SupplierCostUpsertModalComponent, {backdrop: "static"});
    const instance: SupplierCostUpsertModalComponent = modalRef.componentInstance;

    instance.model = {...model};
    instance.period = this.period;
    instance.year = moment(this.period?.startDate).year();

    from(modalRef.result).subscribe(
      success => {
        this.refreshCosts();
      }
    )
  }

  private refreshCosts() {
    if (!this.period) {
      return;
    }

    const year = moment(this.period.startDate).year();

    this.supplierCostService.findByPeriod(this.period.id).subscribe(
      costs => this.costs = costs
    )
  }
}
