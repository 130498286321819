import { Component, OnInit } from '@angular/core';
import { AdvancedPaymentService } from '../../core/services/advanced-payment.service';
import { PeriodService } from '../../core/services/period.service';
import { OwnerService } from '../../core/services/owner.service';
import { from } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedPaymentUpsertDialogComponent } from '../advanced-payment-upsert-dialog/advanced-payment-upsert-dialog.component';
import OwnerModel = HausverwalterRestApi.OwnerModel;
import AdvancePaymentAbstractModel = HausverwalterRestApi.AdvancePaymentAbstractModel;

@Component({
  selector: 'app-advanced-payments-list',
  templateUrl: './advanced-payments-list.component.html',
  styleUrls: ['./advanced-payments-list.component.scss']
})
export class AdvancedPaymentsListComponent implements OnInit {

  private period?: HausverwalterRestApi.PeriodModel;
  ownerWithPayments: OwnerWithPayments[] = [];

  constructor(
    private periodService: PeriodService,
    private ownerService: OwnerService,
    private advancedPaymentService: AdvancedPaymentService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.periodService.current.subscribe(
      period => {
        this.period = period;
        this.refreshPayments();
      }
    );
  }

  refreshPayments() {
    if (!this.period) {
      return;
    }

    const periodId = this.period.id;

    this.ownerWithPayments = [];

    this.ownerService.findAll().subscribe(
      owners => {

        this.ownerWithPayments = owners
          .sort((a, b) => a.id - b.id)
          .filter(o => o.share > 0)
          .map(o => {
            return {owner: o, payments: []} as OwnerWithPayments
          });

        this.ownerWithPayments.forEach(ownerWithPayments =>
          this.advancedPaymentService.findByPeriodIdAndOwnerId(periodId, ownerWithPayments.owner.id)
            .subscribe(
              payments => {
                ownerWithPayments.payments = payments.sort((a, b) => (a.paymentDate > b.paymentDate) ? 1 : -1);
              }
            )
        );
      }
    );
  }

  showCreateDialog(owner: HausverwalterRestApi.OwnerModel) {
    const modalRef = this.modalService.open(AdvancedPaymentUpsertDialogComponent, {backdrop: "static"});
    const instance: AdvancedPaymentUpsertDialogComponent = modalRef.componentInstance;

    instance.owner = owner
    instance.period = this.period;
    instance.paymentDate = this.period?.startDate

    from(modalRef.result).subscribe(
      success => {
        this.refreshPayments();
      }
    )
  }

  showEditDialog(row: HausverwalterRestApi.AdvancePaymentAbstractModel) {
    const modalRef = this.modalService.open(AdvancedPaymentUpsertDialogComponent, {backdrop: "static"});
    const instance: AdvancedPaymentUpsertDialogComponent = modalRef.componentInstance;

    instance.owner = row.owner
    instance.period = this.period;
    instance.paymentDate = row.paymentDate;
    instance.paymentAmount = row.amount;
    instance.uuid = row.uid;

    from(modalRef.result).subscribe(
      success => {
        this.refreshPayments();
      }
    )
  }

  showCopyDialog(row: HausverwalterRestApi.AdvancePaymentAbstractModel) {
    const modalRef = this.modalService.open(AdvancedPaymentUpsertDialogComponent, {backdrop: "static"});
    const instance: AdvancedPaymentUpsertDialogComponent = modalRef.componentInstance;

    instance.owner = row.owner
    instance.period = this.period;
    instance.paymentDate = row.paymentDate;
    instance.paymentAmount = row.amount;

    from(modalRef.result).subscribe(
      success => {
        this.refreshPayments();
      }
    )

  }

  deleteWithConfirmation(row: AdvancePaymentAbstractModel) {
    if (!confirm("Wirklich löschen?")) {
      return;
    }

    // @ts-ignore
    this.advancedPaymentService.delete(this.period?.id, row.owner.id, row.uid).subscribe(
      () => this.refreshPayments(),
      error => alert(error)
    );
  }
}

interface OwnerWithPayments {
  owner: OwnerModel;
  payments: AdvancePaymentAbstractModel[];
}
