import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { SupplierCostService } from "../../core/services/supplier-cost.service";
import SupplierCostModel = HausverwalterRestApi.SupplierCostModel;
import PeriodModel = HausverwalterRestApi.PeriodModel;

@Component({
  selector: 'app-supplier-cost-upsert-modal',
  templateUrl: './supplier-cost-upsert-modal.component.html',
  styleUrls: ['./supplier-cost-upsert-modal.component.scss']
})
export class SupplierCostUpsertModalComponent implements OnInit {

  model: SupplierCostModel = {} as SupplierCostModel;
  period?: PeriodModel;
  year?: number;
  unitText = 'kWh';

  constructor(
    public activeModal: NgbActiveModal,
    private supplierCostService: SupplierCostService,
  ) {
  }

  ngOnInit(): void {
    this.typeChanged();
  }

  save() {
    if (!this.period) {
      alert('Abrechnungsperiode nicht aktiv');
      return;
    }

    let result: Observable<any>;

    if (this.model.id) {
      result = this.supplierCostService.update(this.model);
    } else {
      result = this.supplierCostService.createNew(this.model);
    }

    result.subscribe(
      success => this.activeModal.close()
    );
  }

  typeChanged() {
    if (this.model.type === 'GAS' || this.model.type === 'ELECTRICITY') {
      this.unitText = 'kWh';
    } else if (this.model.type === "WATER") {
      this.unitText = 'm3';
    } else {
      this.unitText = '';
    }
  }
}
