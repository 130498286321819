import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {MeasuredValueService} from "../../core/services/measured-value.service";
import moment from "moment";
import {ConsumptionMeterService} from "../../core/services/consumption-meter.service";
import MeasuredValueUpsertModel = HausverwalterRestApi.MeasuredValueUpsertModel;

@Component({
  selector: 'app-measured-value-edit-dialog',
  templateUrl: './measured-value-edit-dialog.component.html',
  styleUrls: ['./measured-value-edit-dialog.component.scss']
})
export class MeasuredValueEditDialogComponent implements OnInit {

  meterId?: number;
  date?: string;
  model?: MeasuredValueUpsertModel;
  meter: HausverwalterRestApi.ConsumptionMeterModel | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private measuredValueService: MeasuredValueService,
    private consumptionMeterService: ConsumptionMeterService,
  ) {
  }

  ngOnInit(): void {
  }

  save() {
    if (!this.meterId || !this.model || !this.date) {
      alert('invalid state');
      return;
    }

    this.measuredValueService.upsert(this.meterId, this.date, this.model).subscribe(
      success => {
        this.activeModal.close();
      }
    )
  }

  load(model: HausverwalterRestApi.MeasuredValueModel) {
    this.meterId = model.meterId;
    this.date = moment(model.date).format('yyyy-MM-DD');
    this.model = {measureType: model.measureType, value: model.value};

    this.consumptionMeterService.findById(this.meterId).subscribe(
      meter => this.meter = meter
    )
  }
}
