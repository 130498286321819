<div class="modal-header">
  <h4 class="modal-title">Vorauszahlung
    <ng-container *ngIf="owner">
      <h4 class="">{{owner.name}}</h4>
    </ng-container>
  </h4>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()">
  </button>
</div>
<form #form="ngForm" (submit)="save()">
  <div class="modal-body">
    <div class="form-group mb-3">
      <label class="form-label" for="date">Datum</label>
      <input id="date" type="date" name="date" class="form-control"
             [(ngModel)]="paymentDate" required ngbautofocus>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="value">Betrag</label>
      <input id="value" type="number" name="value" class="form-control"
             [(ngModel)]="paymentAmount" required>
    </div>

    <div class="form-group mb-3" *ngIf="!uuid">
      <label class="form-label" for="value">Anzahl Monate</label>
      <input id="count" type="number" name="count" class="form-control" min="1" max="12" step="1"
             [(ngModel)]="count" required>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" tabindex class="btn btn-link link-secondary mr-auto" data-dismiss="modal" (click)="activeModal.dismiss()">
      Abbrechen
    </button>
    <button type="button" class="btn btn-outline-primary" [disabled]="!form.valid" (click)="save()">Speichern</button>
  </div>
</form>
