<div class="content">
  <div class="container">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2 class="page-title">
            Dashboard
          </h2>
        </div>
      </div>
    </div>

    <div class="row row-deck row-cards">
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <p>This is some text within a card body.</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
