import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReportService} from "../../core/services/report.service";
import {PeriodService} from "../../core/services/period.service";
import {takeUntil} from "rxjs/operators";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PdfViewModalComponent} from "../pdf-view-modal/pdf-view-modal.component";
import {Subject, Subscription} from 'rxjs';
import ReportAbstractModel = HausverwalterRestApi.ReportAbstractModel;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {

  private notifier = new Subject();

  public dataBase64?: SafeResourceUrl;
  public reports?: ReportAbstractModel[];
  private period?: HausverwalterRestApi.PeriodModel;

  private $periodSubscription?: Subscription;


  constructor(
    private reportService: ReportService,
    private periodService: PeriodService,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
  this.$periodSubscription =  this.periodService.current
    .pipe(takeUntil(this.notifier))
    .subscribe(
      next => {
        this.period = next;
        this.refreshReports();
      }
    )
  }

  ngOnDestroy() {
    this.notifier.complete();
  }

  private refreshReports() {
    if (!this.period) {
      return;
    }

    this.reportService.fetchByPeriodId(this.period.id).subscribe(
      reports => this.reports = reports
    );
  }

  generateNew(isDraft: boolean) {
    if (!this.period) {
      return;
    }

    this.reportService.generateNewForYear(this.period.id, isDraft).subscribe(
      report => {
        this.refreshReports();
        this.showPdf(report);
      }
    );
  }

  showmodal(id: number) {
    this.reportService.fetchContentByReportId(id)
      .subscribe(
        serviceResult => {
          this.showPdf(serviceResult);
        },
        error => console.error(`Anhang konnte nicht geladen werden: ${error.message}`)
      );

  }

  delete(report: ReportAbstractModel) {
    if (report.draft === false) {
      return;
    }

    this.reportService.deleteById(report.id).subscribe(
      success => this.refreshReports(),
      error => console.error(`Report konnte nicht gelöscht werden: ${error.message}`)
    );
  }

  private showPdf(serviceResult: Blob) {
    const blob = new Blob([serviceResult], {type: 'application/pdf'});

    const modalRef = this.modalService.open(PdfViewModalComponent, {backdrop: 'static', size: 'xl'});
    const previewComponent = modalRef.componentInstance as PdfViewModalComponent;

    previewComponent.load(blob);
  }
}
