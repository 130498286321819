import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pdf-view-modal',
  templateUrl: './pdf-view-modal.component.html',
  styleUrls: ['./pdf-view-modal.component.scss']
})
export class PdfViewModalComponent implements OnInit {

  // @ts-ignore
  data: Blob = undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
  }

  load(data: Blob) {
    this.data = data;
  }

}
