<div class="container-xl">
  <ng-container *ngFor="let ownerWithPayment of ownerWithPayments">
    <div class="row mt-4">
      <div class="col">
        <h3>{{ownerWithPayment.owner.name}}</h3>
      </div>
      <div class="col-auto ms-auto">
        <div class="btn-list">
          <button class="btn btn-sm" (click)="showCreateDialog(ownerWithPayment.owner)">Neu</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="table-responsive" *ngIf="ownerWithPayment.payments.length > 0">
            <table class="table card-table">
              <thead>
              <tr>
                <th>Datum</th>
                <th>Betrag</th>
                <th>Aktion</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let row of ownerWithPayment.payments">
                <td>{{row.paymentDate}}</td>
                <td>{{row.amount}}</td>
                <td>
                  <button class="btn btn-sm btn-secondary mx-1" (click)="showEditDialog(row)">Edit</button>
                  <button class="btn btn-sm btn-secondary mx-1" (click)="showCopyDialog(row)">Copy</button>
                  <button class="btn btn-sm btn-secondary mx-1" (click)="deleteWithConfirmation(row)">Delete</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <p class="mx-auto pt-3" *ngIf="ownerWithPayment.payments.length === 0">
            Keine Vorauszahlungen erfasst</p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
