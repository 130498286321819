import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {LayoutBlankComponent} from './layout-blank/layout-blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AdminLayoutComponent} from './admin-layout/admin-layout.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {NgbCollapseModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgbDropdownModule,
  ],
  declarations: [
    LayoutBlankComponent,
    AdminLayoutComponent,
    HeaderComponent,
    FooterComponent,
  ],
  providers: []
})
export class LayoutModule {
}
